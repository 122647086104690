
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91slug_93U7XL67Dxb4Meta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as indexai3XIoBiCXMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as guidesfFI1ICjMNmMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as _91response_93CuohLADK4GMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as index4XGP9i5zysMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as schedule_45callbackdJi7bOQCKlMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as about_45usOtuRQA7bAwMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as callback78OLPqfX5tMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as mergedwfdvBjSZGMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as buyer_45protection_45claimeb3X0NjXdhMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as terms_45and_45conditionsDYjl9dXnW0Meta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as comprehensiveCyKCJHGlSqMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as indexGApSuj53CvMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91step_93qDjgZTngyJMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as detailsuiKdNkGWYBMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as results09M8ItGLGlMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as _91slug_937wjCcQUKn6Meta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue?macro=true";
import { default as indexJMCzJgsK1MMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as index6tnksie3jQMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as collection_45noticesQzktNT2BaMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as complaintstAsEiJu5BbMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45us2WuF6MDovHMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as _91slug_93kh7t2osQD9Meta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as indexCuzUoJZwuKMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as _91slug_93obbXXx9T4uMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as _91slug_93MhEpuIbNC1Meta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as account_45settingsZBIb3rXHftMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexcOt5YYu0L1Meta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as claim_45offergTOPscGNacMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as indexIlFndmm0MKMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as indexxmkBkhDhVdMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexyHlHL8W2TcMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as indexhkKc5GssmBMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as additional_45detailsdwOHxWlPtUMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as completion4k9vFF4DcgMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as detailsMz6Kmq23aRMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as _91step_93SjJXmrXtxeMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93tBqb5fvL56Meta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93l6Dt2uHAKyMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as _91step_93hjStyFGHjsMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as _91step_93Y3RafPMuOsMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as lic_45detailsDo0vOICwU1Meta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as rebatehnQASmB5swMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as digital_45eicjYwi5fSuecMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as errormkk2Kh2mogMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as thank_45youjJLEfjdvTrMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as coffs_45harbourwLbi0DhkIwMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as indexBs6lJMTlkBMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91step_93UaoQFdeXCZMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as details4OtcaRAXvPMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as thank_45youkuQrRWMshuMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as _91slug_93PLbuZgw7aHMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as indexSSxv9Mzn6aMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as _91author_93dt9aMCwaXzMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as index4S0enc0yyIMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as forgot_45passwordX4bN5Pp7e9Meta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as set_45passwordHt0gmTLmLCMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45backi7P5WmFDLYMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as _91step_933oLQfE3Z2VMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as expiredTz9ux3H5r8Meta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexNJJlvO5W5MMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as indexRL8LLYw7CZMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as errorFd4ap2nZLNMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as index3RuPMXG4W0Meta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as live_45chat_45bunrox8W1t2Meta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as live_45chatDPqJWSuBMiMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as indexI8JewKiFgbMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addtAayaGzW8YMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as signupUKku0FNY5dMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as post_45salekZXfgdN0zOMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as index539yMde6bcMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as _91step_93xMZr655HB2Meta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as detailsnSlT0MMnWOMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step0zqq9Ugo02aMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as detailsUdNRNpIInWMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as _91step_93fDPxMcVxnIMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as step0t9y9SRkXkaMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as indexMZsC3UKv3xMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as thank_45youzgGYXOFnhEMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as indexUVq7imlbIaMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as indexCYVvIpuzSHMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as indexRFFJsTAWL9Meta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as indexPvhXOBnAvHMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as _91step_93IP4a85uaJuMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as _91step_93VpZqmNeUP0Meta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as confirmationKgeScQcNYyMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as detailsykU2Dv4cDYMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as indexN1wzUJ4X9ZMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as quotes_45resultsitRA2QEvNzMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as thank_45youjruVBHhKdhMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as _91type_931K4o14CmpVMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as indexVYZdKPEL2BMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as indexsqMB1YkyFhMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as interest_45ratesEhe71m6tkUMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91step_93VN0AV3GPIjMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as detailsU0RleF1aC6Meta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as thank_45youzVIb0CL7gkMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as index8jdomwIF2JMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/index.vue?macro=true";
import { default as existing_45customers97tAvjUpKEMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as confirmationg4oyL14SYwMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as detailsftdB6ea8t3Meta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as indexmSXn4tVMbaMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as index3DCdFhe6UBMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as add3V4xksdSsBMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as thank_45you68KDsB1X8wMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as indexVGAZSJp9OqMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as successYYVwpKWDLNMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as _91step_93QCpJfholKQMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue?macro=true";
import { default as detailsDR4LD1j3erMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as step0TiXnPeCDYRMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue?macro=true";
import { default as loginq7zBa6cN2EMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/login.vue?macro=true";
import { default as _91slug_93dJoiJ7XMCAMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as _91author_93Y75z2DM2aeMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as indexleiAp01NE5Meta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as partnerships1R0zlhmWyLMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as indexILiaWNfF4kMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91step_93Ej1vRzf2LgMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as detailskaSp2X19PbMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as thank_45youB397mdYAd9Meta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as post_45sale_45thank_45youmMw2oCWS1kMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as _91slug_93ag9R2qGiMGMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93AjsaGTfK9OMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93NUxPdnGF1PMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as privacy_45policycPNcfzjJ8bMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as registerkFPHcfNGleMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/register.vue?macro=true";
import { default as reset_45password3e5Enxut67Meta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as sitemapbYwglWmMVmMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as terms_45of_45useB6osMjB47JMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as verifyGzOjatxsbaMeta } from "/codebuild/output/src603028453/src/packages/nuxt/pages/verify.vue?macro=true";
export default [
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: schedule_45callbackdJi7bOQCKlMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  },
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  }
]
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "car-insurance-redirect-slug",
    path: "/car-insurance/redirect/:slug()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93kh7t2osQD9Meta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingsZBIb3rXHftMeta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexcOt5YYu0L1Meta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offergTOPscGNacMeta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexIlFndmm0MKMeta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexxmkBkhDhVdMeta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexyHlHL8W2TcMeta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: indexhkKc5GssmBMeta?.props ?? {"verificationNeeded":true},
    meta: indexhkKc5GssmBMeta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product(.*)*/:vertical()/additional-details",
    meta: additional_45detailsdwOHxWlPtUMeta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product(.*)*/:vertical()/completion",
    meta: completion4k9vFF4DcgMeta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product(.*)*/details",
    meta: detailsMz6Kmq23aRMeta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product(.*)*/form/energy/step/:step()",
    meta: _91step_93SjJXmrXtxeMeta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product(.*)*/form/generic/step/:step()",
    meta: _91step_93tBqb5fvL56Meta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product(.*)*/form/health-insurance/step/:step()",
    meta: _91step_93l6Dt2uHAKyMeta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product(.*)*/form/home-loan/step/:step()",
    meta: _91step_93hjStyFGHjsMeta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product(.*)*/form/life-insurance/step/:step()",
    meta: _91step_93Y3RafPMuOsMeta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product(.*)*/lic-details",
    meta: lic_45detailsDo0vOICwU1Meta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product(.*)*/rebate",
    meta: rebatehnQASmB5swMeta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordX4bN5Pp7e9Meta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45passwordHt0gmTLmLCMeta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_93fDPxMcVxnIMeta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "life-insurance-quote-form-step",
    path: "/life-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "life-insurance-quote-form-step0",
    path: "/life-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginq7zBa6cN2EMeta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerkFPHcfNGleMeta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password3e5Enxut67Meta || {},
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src603028453/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]